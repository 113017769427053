<template>
  <div class="AnimationSettingsMain">
    <div>
      <p class="pageSettingsTitle">Animation</p>
    </div>

    <div class="mt-1 d-flex align-items-center justify-content-between">
      <p class="m-0 p-0 pageSettingHeader">Use Animation</p>
      <div class="show-logo custom-control custom-switch cursor-pointer">
        <input
          type="checkbox"
          class="custom-control-input"
          :id="`AnimationSwitch-${Date.now()}`"
          v-model="animation.show"
        />

        <label
          class="custom-control-label"
          :for="`AnimationSwitch-${Date.now()}`"
        ></label>
      </div>
    </div>

    <div class="">
      <div class="w-100 mt-2">
        <div class="mb-3">
          <span class="pageSettingHeader">Direction</span>
        </div>
        <div class="row">
          <div class="col-6 mb-3">
            <div
              class="animationBox"
              @click="animation.direction = 'BTT'"
              :class="animation.direction == 'BTT' ? 'selectedAnimation' : null"
            >
              <b-icon
                icon="arrow-bar-up"
                font-scale="2"
                animation="cylon-vertical"
              />
              <p class="m-0 mt-2">Rise</p>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div
              class="animationBox"
              @click="animation.direction = 'LTR'"
              :class="animation.direction == 'LTR' ? 'selectedAnimation' : null"
            >
              <b-icon icon="arrow-bar-right" font-scale="2" animation="cylon" />
              <p class="m-0 mt-2">Pan</p>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div
              class="animationBox"
              @click="animation.direction = 'RTL'"
              :class="animation.direction == 'RTL' ? 'selectedAnimation' : null"
            >
              <b-icon icon="arrow-bar-left" font-scale="2" animation="cylon" />
              <p class="m-0 mt-2">Pan</p>
            </div>
          </div>
          <div class="col-6">
            <div
              class="animationBox"
              @click="animation.direction = 'TTB'"
              :class="animation.direction == 'TTB' ? 'selectedAnimation' : null"
            >
              <b-icon
                icon="arrow-bar-down"
                font-scale="2"
                animation="cylon-vertical"
              />
              <p class="m-0 mt-2">Rise</p>
            </div>
          </div>
          <div class="col-6">
            <div
              class="animationBox"
              @click="animation.direction = 'POP'"
              :class="animation.direction == 'POP' ? 'selectedAnimation' : null"
            >
              <b-icon
                icon="arrows-angle-expand"
                font-scale="2"
                animation="throb"
              />
              <p class="m-0 mt-2">Pop</p>
            </div>
          </div>
          <div class="col-6">
            <div
              class="animationBox"
              @click="animation.direction = 'FLIP'"
              :class="
                animation.direction == 'FLIP' ? 'selectedAnimation' : null
              "
            >
              <b-icon
                icon="square-half"
                font-scale="2"
                class="flipX-animation"
              />
              <p class="m-0 mt-2">Flip</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex align-items-center mt-3">
        <div class="">
          <p class="pageSettingHeader m-0 p-0">Speed</p>
        </div>
        <div class="d-flex align-items-center">
          <input
            type="number"
            min="0"
            max="5"
            v-model="animation.speed"
            class="form-control-sm d-block ml-3 mr-1"
            style="
              width: 80%;min-width:50px;
              background: transparent;
              color: #4d4950;
              border: 0.8px solid #d2d8e0;
            "
          />
          <span class="ml-1">seconds</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      animation: {},
    };
  },
  computed: {
    ...mapGetters(["getAnimationSetting"]),
    currentAnimationValue() {
      return this.getAnimationSetting;
    },
  },
  watch: {
    animation: {
      deep: true,
      handler() {
        this.setAnimationValue(this.animation);
      },
    },
  },
  methods: {
    ...mapMutations(["setAnimationValue"]),
  },

  mounted() {
    if (this.currentAnimationValue) {
      this.animation = this.currentAnimationValue;
    }
  },
};
</script>

<style scoped>
.size-input {
  width: 60px;
  height: 20px;
  outline: #c4c4c4 solid 1px;
  background: transparent;
  border-radius: 4px;
}

/* New Design */

.animationBox {
  padding: 20px 0px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f4f3f5;
  color: #6d6b6d;
  cursor: pointer;
}

.selectedAnimation {
  background: #f0b429;
  color: #fff;
}

@keyframes flipX {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.flipX-animation {
  animation: flipX 2s infinite;
  transform-style: preserve-3d;
}

.custom-control-input:checked ~ .custom-control-label::before {
            background-color: #ffc107 !important; 
            border-color: #ffc107 !important; 
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #fff !important; 
        }
</style>
